import React from 'react';
import {Doughnut} from 'react-chartjs-2';

class DoughnutComponent extends React.PureComponent {

    componentRef = React.createRef();

    render() {
        return (
            <div className="relative">
                <Doughnut ref={this.componentRef} data={this.props.data} options={this.props.options} width={160}/>
                <div className="absolute-center text-center">
                    <div>
                        <i className="fa fa-bar-chart" aria-hidden="true"/>
                        <p className="m-0 hour">{(this.props.centerValue)}</p>
                        {this.props.hours ? (
                            <p className="m-0 hour-text">{this.props.centerValue > 1 ? "Hours" : "Hour"}</p>
                        ): (
                            <p className="m-0 hour-text">People</p>
                        )}
                    </div>
                </div>
            </div>
        );
    }
};
export default DoughnutComponent;