import React, {useEffect} from 'react';
import {Route, Switch} from "react-router-dom";
import Footer from "../components/CommonComponent/Footer";
import Header from "../components/CommonComponent/Header";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import About from "../containers/About";
import TryBeta from "../containers/TryBeta";
import Dashboard from "../containers/Dashboard";
import ReactGA from "react-ga";
import {withRouter} from "react-router-dom";
import PrivacyPolicy from "../containers/PrivacyPolicy";

ReactGA.initialize("UA-225223768-1");

const Routes = () => {

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return (
        <div>
            <Header/>
            <Switch>
                <Route path="/" exact={true}>
                    <TryBeta/>
                </Route>
                <Route path="/role-type" exact={true}>
                    <About/>
                </Route>
                <Route path="/dashboard" exact={true}>
                    <Dashboard/>
                </Route>
                <Route path="/privacy-policy" exact={true}>
                    <PrivacyPolicy/>
                </Route>
                <Route component={PageNotFound}/>
            </Switch>
            <Footer/>
        </div>
    );
};

export default withRouter(Routes);