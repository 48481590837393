import DoughnutComponent from "../../components/CommonComponent/Doughnut";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import Loader from "../../components/CommonComponent/Loader";


const optionsForDoughnut = {
    responsive: true,
    maintainAspectRatio: true,
    cutoutPercentage: 80,
    legend: {
        position: "bottom",
        display: true,
        labels: {
            boxWidth: 15,
            fontSize: 12,
            fontColor: "gray"
        }
    }
};

const roleArray = [
    {
        analyze: "According to our research, most doers need at least four hours of uninterrupted focus time per day. To maintain concentration, meeting diversity (unique meeting participants) should be minimal.",
        optimization: "The optimization widgets analyze your average (past 30 days) and daily (past 5 days) optimization based on the role you selected."
    },
    {
        analyze: "According to our research, most sellers need a fairly open schedule for potential client meetings in addition to high meeting externality.",
        optimization: "The optimization widgets analyze your average (past 30 days) and daily (past 5 days) optimization based on the role you selected."
    },
    {
        analyze: "According to our research, most managers need a fairly open schedule to address real-time issues in addition to high meeting attendee diversity.",
        optimization: "The optimization widgets analyze your average (past 30 days) and daily (past 5 days) optimization based on the role you selected."
    },
    {
        analyze: "According to our research, most leaders need a somewhat open schedule for deep thought in addition to high meeting externality and diversity.",
        optimization: "The optimization widgets analyze your average (past 30 days) and daily (past 5 days) optimization based on the role you selected."
    },
]

const Dashboard = () => {
    const location = useLocation();
    const history = useHistory();
    const componentRef = useRef();

    const [form, setForm] = useState({
        role: ""
    });
    const [intExtGraph, setIntExtGraph] = useState([]);
    const [organizerDuration, setOrganizerDuration] = useState([]);
    const [attendeesData, setAttendeesData] = useState([]);
    const [topFiveDistData, setTopFiveDistData] = useState([]);
    const [weekdayAverage, setWeekdayAverage] = useState([]);
    const [optimizedPercentage, setOptimizedPercentage] = useState(0);
    const [graph1Data, setGraph1Data] = useState();
    const [graph3Data, setGraph3Data] = useState();
    const [graph1CenterValue, setGraph1CenterValue] = useState();
    const [graph3CenterValue, setGraph3CenterValue] = useState();
    const [graph2Data, setGraph2Data] = useState([]);
    const [graph4Data, setGraph4Data] = useState([]);
    const [graph5Data, setGraph5Data] = useState([]);
    const [graphsData, setGraphsData] = useState();
    const [roleIndex, setRoleIndex] = useState();
    const [role, setRole] = useState();
    const [user, setUser] = useState();
    const [calendarData, setCalendarData] = useState();
    const [isFile, setIsFile] = useState();
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [email, setEmail] = useState();
    const [calendar, setCalendar] = useState();
    const [loading, setLoader] = useState(false);

    useEffect(() => {
        if (location?.state?.data) {
            setCalendarData(location?.state?.calendarResult);
            setIsFile(location?.state?.isFile);
            setFile(location?.state?.file);
            setFileName(location?.state?.filename);
            setEmail(location?.state?.email);
            setCalendar(location?.state?.calendar);
            setUser(location?.state?.user);
            setForm({
                role: location?.state?.role,
            })
            const currentRole = indexOfRole(location?.state?.role);
            setRole(location?.state?.role);
            setRoleIndex(currentRole);
            setGraphsData(location?.state?.data)
        } else {
            history.push({pathname: "/"});
            toast.warning("Please upload a file before visiting dashboard.");
        }
        //eslint-disable-next-line
    }, [location?.state]);

    useEffect(() => {
        setIntExtGraph(graphsData?.get_attend_int_ext_dict?.get_attend_int_ext_dict_data);
        setAttendeesData(graphsData?.get_time_attendee_dict?.get_time_attendee_dict_data);
        setOrganizerDuration(graphsData?.get_duration_organizer_dict);
        setWeekdayAverage(graphsData?.get_weekday_averages_dict);
        setOptimizedPercentage(graphsData?.get_optimzation_dict * 100)
        setTopFiveDistData(graphsData?.last_5_opt_type_dict);
    },[graphsData]);

    useEffect(()=>{
        if(calendar){
            setLoader(true);
            if(isFile){
                const formData = new FormData();
                formData.append('key', file, fileName);
                formData.append('email', email);
                formData.append('user', form.role);
                axios({
                    method: "post",
                    url: `https://beta-api.nitetoast.com/${calendar}/`,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data", "Accept": "application/json"},
                })
                    .then((response) => {
                        setLoader(false);
                        setGraphsData(response?.data?.data);
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoader(false);
                    });
            }else {
                const formData = new FormData();
                formData.append('events', JSON.stringify(calendarData));
                formData.append('email', email);
                formData.append('user', form.role);
                axios({
                    method: "post",
                    url: `https://beta-api.nitetoast.com/${calendar}json2csv/`,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data", "Accept": "application/json"},
                })
                    .then((response) => {
                        if (!response?.data?.data) {
                            setLoader(false);
                            toast.warning(response?.data?.meta?.message)
                        } else {
                            setGraphsData(response?.data?.data);
                            setLoader(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoader(false);
                    });
            }
        }
        // eslint-disable-next-line
    },[form?.role, calendar])

    useEffect(() => {
        if (intExtGraph) {
            let data = intExtGraph?.Attendee?.map((each_element) => {
                return Number(each_element.toFixed(2));
            });
            const dataOfDoughnut2 = {
                labels: intExtGraph?.External,
                datasets: [
                    {
                        fill: false,
                        backgroundColor: ['#7fe941', '#ffc526'],
                        borderCapStyle: 'butt',
                        data: data,
                        borderWidth: 0,
                    }
                ]
            };
            setGraph3Data(dataOfDoughnut2);
            const sum = intExtGraph?.Attendee?.reduce((partial_sum, a) => partial_sum + a, 0);
            setGraph3CenterValue(sum);
        }

        if (attendeesData) {
            let data = attendeesData?.Duration_Hours?.map((each_element) => {
                return Number(each_element.toFixed(2));
            });
            const dataOfDoughnut = {
                labels: attendeesData?.Attendee_Bucket,
                datasets: [
                    {
                        fill: false,
                        backgroundColor: ['#8893fb', '#ffc526', '#7fe941'],
                        borderCapStyle: 'butt',
                        data: data,
                        borderWidth: 0,
                    }
                ]
            };
            setGraph1Data(dataOfDoughnut);
            const sum = attendeesData?.Duration_Hours?.reduce((partial_sum, a) => partial_sum + a, 0);
            setGraph1CenterValue(Math.round(sum));
        }

        if (weekdayAverage) {
            const lengthOfData = weekdayAverage?.Average?.length;
            let ids = [];
            for (let id = 1; id <= lengthOfData; id++) {
                ids.push(id);
            }
            if (weekdayAverage?.Average && weekdayAverage['Day of Week']) {
                const data = ids?.map((id, index) => {
                    return {
                        id: id,
                        average: weekdayAverage?.Average[index],
                        days: weekdayAverage['Day of Week'][index]
                    }
                });
                setGraph2Data(data);
            }
        }

        if (organizerDuration) {
            const lengthOfData = organizerDuration?.Duration_Hours?.length;
            let ids = [];
            for (let id = 1; id <= lengthOfData; id++) {
                ids.push(id);
            }
            if (organizerDuration?.Duration_Hours && organizerDuration['Meeting Organizer']) {
                const data = ids.map((id, index) => {
                    return {
                        id: id,
                        hours: organizerDuration?.Duration_Hours[index],
                        organizer: organizerDuration['Meeting Organizer'][index]
                    }
                });
                setGraph4Data(data);
            }
        }

        if (topFiveDistData) {
            let key = "User_" + role + "_Score";
            const lengthOfData = topFiveDistData?.Date?.length;
            let ids = [];
            for (let id = 1; id <= lengthOfData; id++) {
                ids.push(id);
            }
            if (topFiveDistData?.Date && topFiveDistData?.[key]) {
                const data = ids?.map((id, index) => {
                    return {
                        id: id,
                        dates: topFiveDistData?.Date[index],
                        user_dore_score: topFiveDistData?.[key][index]
                    }
                });
                setGraph5Data(data);
            }
        }
        //eslint-disable-next-line
    }, [intExtGraph, attendeesData, weekdayAverage, organizerDuration, topFiveDistData]);

    const handleChange = (e) => {
        setForm({
            role: e.target.value
        })
        const currentRole = indexOfRole(e.target.value);
        setRole(e.target.value);
        setRoleIndex(currentRole);
    };

    const indexOfRole = (role) => {
        if (role === "Doer") {
            return 0
        }
        if (role === "Seller") {
            return 1
        }
        if (role === "Manager") {
            return 2
        }
        if (role === "Leader") {
            return 3
        }
    }

    const decimalToHourAndMin = (decimalTimeString) => {
        if (decimalTimeString === 120) {
            return 24 + " hrs";
        } else {
            const n = new Date(0, 0);
            n.setMinutes(+decimalTimeString * 60);
            const hourAndMin = n.toTimeString().slice(0, 5).split(":");
            // eslint-disable-next-line
            return `${hourAndMin[0] > 0 ? hourAndMin[0] > 1 ? hourAndMin[0] + " hrs " : hourAndMin[0] + " hr " : ""}` + `${hourAndMin[1] > 0 ? hourAndMin[1] + " min" : ""}`
        }
        ;
    }

    const getHoursAndMinPercentage = (decimalTimeString) => {
        const n = new Date(0, 0);
        n.setMinutes(+decimalTimeString * 60);
        const HourAndTime = n.toTimeString().slice(0, 5);
        const timeParts = HourAndTime.split(":");
        const minutes = Number(timeParts[0]) * 60 + Number(timeParts[1]);
        const percentage = (minutes / 480) * 100;
        return parseFloat(percentage).toFixed(2) + "%"
    }

    return (
        <> {loading && <Loader/>}
            <div className="trybeta">
                <section id="graph-section">
                    <div className="container-fluid graphs">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-9">
                                <h4 className="main-text">Analytics</h4>
                                <h5 className="sub-text mt-0"><span
                                    className="text-capitalize">{user?.name}</span>,
                                    welcome
                                    to the future of work.</h5>
                                <p className="sub-text-para">Your last 30 days in meetings.</p>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-evenly mt-md-4 mt-2 graphs-container">
                            <div className="d-flex flex-wrap graphs-group justify-content-around">
                                <div className="graph-bg graph-1 mt-3">
                                    <div className="d-flex justify-content-between">
                                        <p className="graph-head">Time in meetings</p>
                                        {/*<p className="graph-month">This month</p>*/}
                                    </div>
                                    {graph1Data &&
                                    <DoughnutComponent ref={componentRef} data={graph1Data}
                                                       centerValue={graph1CenterValue}
                                                       hours={true}
                                                       options={optionsForDoughnut}/>
                                    }
                                </div>
                                {
                                    weekdayAverage && (
                                        <div className="graph-bg graph-2 mt-3">
                                            <div className="d-flex justify-content-between">
                                                <p className="graph-head">Average time in meetings</p>
                                                {/*<p className="graph-month">This month</p>*/}
                                            </div>
                                            <div className="mt-3">
                                                {
                                                    graph2Data?.length > 0 ? graph2Data.map((data, index) => {
                                                        return (
                                                            <div className="d-flex justify-content-evenly my-4"
                                                                 key={index}>
                                                        <span
                                                            className="m-0 align-self-center day mx-2">{data.days.slice(0, 3)}</span>
                                                                <div
                                                                    className="d-block w-100 align-self-center progress-container">
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar"
                                                                             style={{
                                                                                 width: getHoursAndMinPercentage(data.average),
                                                                                 background: '#4d65f8'
                                                                             }}
                                                                             aria-valuenow="25" aria-valuemin="0"
                                                                             aria-valuemax="100"/>
                                                                    </div>
                                                                </div>
                                                                <span
                                                                    className="align-self-center mx-2 hours">{decimalToHourAndMin(data.average)}</span>
                                                            </div>
                                                        )
                                                    }) : <p className="no-data text-center">No data found.</p>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="d-flex flex-wrap graphs-group justify-content-around">
                                <div className="graph-bg graph-3 mt-3">
                                    <div className="d-flex justify-content-between">
                                        <p className="graph-head">Attendee type</p>
                                        {/*<p className="graph-month">This month</p>*/}
                                    </div>
                                    {graph3Data &&
                                    <DoughnutComponent ref={componentRef} data={graph3Data}
                                                       centerValue={graph3CenterValue}
                                                       hours={false}
                                                       options={optionsForDoughnut}/>
                                    }
                                </div>
                                {
                                    organizerDuration && (
                                        <div className="graph-bg graph-bg-4 mt-3">
                                            <div className="graph-bg-4-inner">
                                                <div className="d-flex justify-content-between position-sticky top-0"
                                                     style={{backgroundColor: "#ffffff"}}>
                                                    <p className="graph-head">Who you`re meeting most</p>
                                                    {/*<p className="graph-month">This month</p>*/}
                                                </div>
                                                {
                                                    graph4Data?.length > 0 ? graph4Data.map((data, index) => {
                                                        return (
                                                            <div className="graph-4" key={index}>
                                                                <div className="d-flex justify-content-between my-3">
                                                            <span className="align-self-center color"
                                                                  style={{backgroundColor: "#" + Math.floor(100000 + Math.random() * 900000)}}/>
                                                                    <div className="align-self-center name-email">
                                                                        <p className="m-0 email"
                                                                           data-toggle="tooltip"
                                                                           data-placement="top"
                                                                           title={data.organizer}>{data.organizer}</p>
                                                                    </div>
                                                                    <p className="align-self-center time m-0 text-nowrap">{decimalToHourAndMin(data.hours)}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : <p className="no-data text-center">No data found.</p>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <div className="graph-container-2 mt-md-2 mt-0">
                           <div className="row">
                               <div className="mt-5 col-lg-3 col-md-4 col-12">
                                   <select value={form?.role} onChange={handleChange} className="form-select">
                                       <option value="Doer">Doer</option>
                                       <option value="Seller">Seller</option>
                                       <option value="Manager">Manager</option>
                                       <option value="Leader">Leader</option>
                                   </select>
                               </div>
                               <div className="mt-md-5 mt-3 col-lg-9 col-md-8 col-12">
                                   <p className="m-0 mt-1 click_here"><a href="https://nitetoast.com/blog/optimization" rel="noreferrer" target="_blank">Click here</a> for tips on how to optimize your schedule.</p>
                               </div>
                           </div>
                            <div className="d-flex flex-wrap justify-content-around">
                                <div className="role-details mt-4">
                                    <h2 className="h2-heading">Your role: {form?.role}</h2>
                                    <p className="para">How we analyze your calendar</p>
                                    <p className="para">{roleArray[roleIndex]?.analyze}</p>
                                    <p className="para">Average and daily optimization:</p>
                                    <p className="para">{roleArray[roleIndex]?.optimization}</p>
                                </div>
                                <div className="optimize-result mt-4">
                                    <div className="d-flex justify-content-between">
                                        <p className="graph-head">Average optimization:</p>
                                    </div>
                                    <div className="circular-graph">
                                        <CircularProgressbar
                                            value={optimizedPercentage?.toFixed(0)}
                                            strokeWidth={3}
                                            styles={buildStyles({
                                                strokeLinecap: 'round',
                                                pathTransitionDuration: 1.5,
                                                pathColor: `rgba(49, 167, 199, ${optimizedPercentage?.toFixed(0) / 100})`,
                                                trailColor: '#ffffff',
                                            })}
                                        />
                                        <div className="circular-graph-text text-center">
                                            {optimizedPercentage?.toFixed(0)}%
                                            <p>Optimized</p>
                                        </div>
                                    </div>
                                    {/*<i className="fa fa-info-circle icon fa-2x" aria-hidden="true"/>*/}
                                </div>
                                <div className="schedule-optimization mt-4">
                                    <div className="d-flex justify-content-between">
                                        <p className="graph-head align-self-center">Schedule optimization</p>
                                    </div>
                                    <div className="mt-4">
                                        {graph5Data?.length > 0 ? graph5Data?.map((data, index) => {
                                            return (
                                                <div className="d-flex justify-content-evenly my-4" key={index}>
                                                <span
                                                    className="m-0 align-self-center time">{moment(data?.dates).format("ll")}</span>
                                                    <div className="d-block w-100 progress-container">
                                                        <div className="progress">
                                                            <div className="progress-bar" role="progressbar"
                                                                 style={{
                                                                     width: `${data?.user_dore_score * 100}%`,
                                                                     backgroundColor: "#" + Math.floor(100000 + Math.random() * 900000)
                                                                 }}
                                                                 aria-valuenow={parseInt(data?.user_dore_score)}
                                                                 aria-valuemin="0" aria-valuemax="100"/>
                                                        </div>
                                                    </div>
                                                    <span
                                                        className="align-self-center mx-3 percentage">{(data?.user_dore_score * 100)?.toFixed(0) + "%"}</span>
                                                </div>
                                            )
                                        }) : <p className="no-data text-center">No data found.</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Dashboard;