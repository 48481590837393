import {
    BrowserRouter as Router,
} from "react-router-dom";
import Routes from "./routes";
import ScrollToTop from "./components/CommonComponent/ScrollToTop";
import {ToastContainer} from "react-toastify";

function App() {
    return (
        <div className="App">
            <ToastContainer autoClose={2000}/>
            <Router>
                <ScrollToTop>
                    <div>
                        <Routes/>
                    </div>
                </ScrollToTop>
            </Router>
        </div>
    );
}

export default App;
