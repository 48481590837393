import React from 'react';

const PrivacyPolicy = () => {

    const scrollToEle = (id) =>{
        const topOfElement = document.querySelector(id).offsetTop - 100;
        window.scroll({ top: topOfElement, behavior: "smooth" });
    }

    return (<div className="privacy-policy">
        <div className="py-5">
            <div className="container">
                <h1>PRIVACY NOTICE</h1>
                <span>Last updated May 14, 2022</span>

                <div className="my-4">
                    <p>
                        This privacy notice for Nitetoast LLC (doing business as Nitetoast)
                        (<span>"Nitetoast," "we," "us,"</span> or
                        <span>"our"</span>), describes how and why we might collect, store, use, and/or share
                        (<span>"process"</span>) your
                        information when you use our services (<span>"Services"</span>), such as when you:
                    </p>
                    <ul>
                        <li>
                            Visit our website at <a href="https://nitetoast.com/"
                                                    target="_blank" rel="noreferrer">https://nitetoast.com/</a>, or any
                            website of ours
                            that links to this privacy notice
                        </li>
                        <li>
                            Engage with us in other related ways, including any sales, marketing, or events
                        </li>
                    </ul>
                    <p><span>Questions or concerns?</span> Reading this privacy notice will help you understand your
                        privacy rights
                        and choices. If you do not agree with our policies and practices, please do not use our
                        Services. If you still have any questions or concerns, please contact us at <a
                            href="mailto:hello@nitetoast.com">hello@nitetoast.com</a>.</p>
                </div>

                <div className="my-5">
                    <h2>SUMMARY OF KEY POINTS</h2>
                    <span><em>This summary provides key points from our privacy notice, but you can find out more details about any of
                these topics by clicking the link following each key point or by using our table of contents below to
                    find the section you are looking for. You can also <span className="cursor"
                                                                             onClick={() => scrollToEle("#table_contents")}>click</span> here to go directly to our table of contents.</em></span>
                    <p className="mt-3"><span>What personal information do we process?</span> When you visit, use, or
                        navigate our
                        Services, we may process
                        personal information depending on how you interact with Nitetoast and the Services, the choices
                        you
                        make, and the products and features you use. <span className="cursor"
                                                                           onClick={() => scrollToEle("#personal_info")}>Click</span> here
                        to learn more.</p>
                    <p><span>Do we process any sensitive personal information?</span> We may process sensitive personal
                        information when
                        necessary with your consent or as otherwise permitted by applicable law. <span
                            className="cursor" onClick={() => scrollToEle("#sensitive_info")}>Click</span> here to learn
                        more.</p>
                    <p><span>Do we receive any information from third parties?</span> We do not receive any information
                        from
                        third parties.</p>
                    <p>
                        <span>
                            How do we process your information?
                        </span> We process your information to provide, improve, and administer our
                        Services, communicate with you, for security and fraud prevention, and to comply with law. We
                        may
                        also
                        process your information for other purposes with your consent. We process your information only
                        when
                        we
                        have a valid legal reason to do so. <span className="cursor"
                                                                  onClick={() => scrollToEle("#infouse")}>Click</span> here
                        to learn more.</p>
                    <p><span>In what situations and with which parties do we share personal information?</span> We may
                        share
                        information in
                        specific situations and with specific third parties. <span className="cursor"
                                                                                   onClick={() => scrollToEle("#whoshare")}>Click</span> here
                        to learn more.</p>
                    <p><span>How do we keep your information safe?</span> We have organizational and technical processes
                        and
                        procedures in
                        place to protect your personal information. However, no electronic transmission over the
                        internet or
                        information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                        guarantee
                        that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our
                        security and improperly collect, access, steal, or modify your information. <span
                            className="cursor" onClick={() => scrollToEle("#infosafe")}>Click</span> here to learn
                        more.</p>
                    <p><span>What are your rights?</span> Depending on where you are located geographically, the
                        applicable
                        privacy law may
                        mean you have certain rights regarding your personal information. <span className="cursor"
                                                                                                onClick={() => scrollToEle("#privacyrights")}>Click</span> here
                        to learn more.
                    </p>
                    <p><span>How do I exercise my rights?</span> The easiest way to exercise your rights is by filling
                        out
                        our data subject
                        request form available <a
                            href="https://app.termly.io/notify/0bf7e9d0-109f-4f42-926c-3dd50e579995" target="_blank"
                            rel="noreferrer">here</a>, or by contacting us. We will consider and act upon any request in
                        accordance with applicable data protection laws.</p>
                    <p> Want to learn more about what Nitetoast does with any information we collect? <span
                        className="cursor" onClick={() => scrollToEle("#table_contents")}>Click</span> here to review
                        the
                        notice in full.
                    </p>
                </div>

                <div className="my-5" id="table_contents">
                    <h2>TABLE OF CONTENTS</h2>
                    <ol>
                        <li><span className="cursor" onClick={() => scrollToEle("#infocollect")}>WHAT INFORMATION DO WE COLLECT?</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#infouse")}>HOW DO WE PROCESS YOUR INFORMATION?</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#legalbases")}>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#whoshare")}>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#cookies")}>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#sociallogins")}>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#inforetain")}>HOW LONG DO WE KEEP YOUR INFORMATION?</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#infosafe")}>HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#infominors")}>DO WE COLLECT INFORMATION FROM MINORS?</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#privacyrights")}>WHAT ARE YOUR PRIVACY RIGHTS?</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#dnt")}>CONTROLS FOR DO-NOT-TRACK FEATURES</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#caresidents")}>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#policyupdates")}>DO WE MAKE UPDATES TO THIS NOTICE?</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#contacts")}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                        </li>
                        <li><span className="cursor" onClick={() => scrollToEle("#request")}>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span>
                        </li>
                    </ol>
                </div>

                <div id="infocollect" className="my-5">
                    <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
                    <div className="my-3" id="personal_info">
                        <h3>Personal information you disclose to us</h3>
                        <p><span>In Short:</span> We collect personal information that you provide to us.</p>
                        <p>We collect personal information that you voluntarily provide to us when you register on the
                            Services,
                            express an interest in obtaining information about us or our products and Services, when you
                            participate in activities on the Services, or otherwise when you contact us.</p>
                        <p><span>Personal Information Provided by You.</span> The personal information that we collect
                            depends
                            on the context
                            of your interactions with us and the Services, the choices you make, and the products and
                            features
                            you use. The personal information we collect may include the following:</p>
                        <ul className="text-capitalize">
                            <li>names</li>
                            <li>phone numbers</li>
                            <li>email addresses</li>
                            <li>job titles</li>
                            <li>contact preferences</li>
                            <li>calendar data</li>
                        </ul>
                        <p id="sensitive_info"><span>Sensitive Information.</span> When necessary, with your consent or
                            as otherwise permitted
                            by
                            applicable law, we process the following categories of sensitive information:</p>
                        <ul className="text-capitalize">
                            <li>calendar data</li>
                        </ul>
                        <p>
                            Payment Data. We may collect data necessary to process your payment if you make purchases,
                            such
                            as
                            your payment instrument number (such as a credit card number), and the security code
                            associated
                            with
                            your payment instrument. All payment data is stored by Stripe. You may find their privacy
                            notice
                            link(s) here: <a href="https://stripe.com/privacy"
                                             target="_blank" rel="noreferrer">https://stripe.com/privacy</a>.
                        </p>
                        <p><span>Social Media Login Data.</span> We may provide you with the option to register with us
                            using
                            your existing
                            social media account details, like your Facebook, Twitter, or other social media account. If
                            you
                            choose to register in this way, we will collect the information described in the section
                            called
                            <span className="cursor" onClick={() => scrollToEle("#sociallogins")}>"HOW
                            DO WE HANDLE YOUR SOCIAL LOGINS?" below.</span></p>
                        <p>All personal information that you provide to us must be true, complete, and accurate, and you
                            must
                            notify us of any changes to such personal information</p>
                    </div>
                    <div className="my-3">
                        <h3>Information automatically collected</h3>
                        <p><span>In Short:</span> Some information — such as your Internet Protocol (IP) address and/or
                            browser and device characteristics — is collected automatically when you visit our Services.
                        </p>
                        <p>We automatically collect certain information when you visit, use, or navigate the Services.
                            This
                            information does not reveal your specific identity (like your name or contact information)
                            but
                            may include device and usage information, such as your IP address, browser and device
                            characteristics, operating system, language preferences, referring URLs, device name,
                            country,
                            location, information about how and when you use our Services, and other technical
                            information.
                            This information is primarily needed to maintain the security and operation of our Services,
                            and
                            for our internal analytics and reporting purposes.</p>
                        <p>Like many businesses, we also collect information through cookies and similar
                            technologies.</p>
                        <p>The information we collect includes:</p>
                        <ul>
                            <li>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and
                                performance information our servers automatically collect when you access or use our
                                Services and which we record in log files. Depending on how you interact with us, this
                                log
                                data may include your IP address, device information, browser type, and settings and
                                information about your activity in the Services (such as the date/time stamps associated
                                with your usage, pages and files viewed, searches, and other actions you take such as
                                which
                                features you use), device event information (such as system activity, error reports
                                (sometimes called "crash dumps"), and hardware settings).
                            </li>
                            <li>Device Data. We collect device data such as information about your computer, phone,
                                tablet,
                                or other device you use to access the Services. Depending on the device used, this
                                device
                                data may include information such as your IP address (or proxy server), device and
                                application identification numbers, location, browser type, hardware model, Internet
                                service
                                provider and/or mobile carrier, operating system, and system configuration information.
                            </li>
                            <li>Calendar data. Calendar data is information from your personal or professional calendar.
                                Including, but not limited to who, where, what, how you are meeting based on meeting
                                invites
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="my-5" id="infouse">
                    <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                    <p><span>In Short:</span> We process your information to provide, improve, and administer our
                        Services,
                        communicate
                        with you, for security and fraud prevention, and to comply with law. We may also process your
                        information for other purposes with your consent.</p>
                    <p><span>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</span>
                    </p>
                    <ul>
                        <li>
                            <span>To facilitate account creation and authentication and otherwise manage user accounts.</span> We
                            may
                            process your information so you can create and log in to your account, as well as keep your
                            account in working order.
                        </li>
                        <li><span>To deliver and facilitate delivery of services to the user.</span> We may process your
                            information to provide you with the requested service.
                        </li>
                        <li><span>To send administrative information to you.</span> We may process your information to
                            send
                            you details
                            about our products and services, changes to our terms and policies, and other similar
                            information.
                        </li>
                        <li><span>To fulfill and manage your orders.</span> We may process your information to fulfill
                            and
                            manage your
                            orders, payments, returns, and exchanges made through the Services.
                        </li>
                        <li>
                            <span>To enable user-to-user communications.</span> We may process your information if you
                            choose to use any
                            of our offerings that allow for communication with another user.
                        </li>
                        <li>
                            <span>To save or protect an individual's vital interest.</span> We may process your
                            information
                            when
                            necessary to save or protect an individual’s vital interest, such as to prevent harm.
                        </li>
                    </ul>
                </div>

                <div id="legalbases" className="my-5">
                    <h2>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
                    <p><span>In Short:</span> We only process your personal information when we believe it is necessary
                        and
                        we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your
                        consent, to comply with laws, to provide you with services to enter into or fulfill our
                        contractual
                        obligations, to protect your rights, or to fulfill our legitimate business interests.
                    </p>
                    <p><span className="text-decoration-underline"><em>If you are located in the EU or UK, this section applies to you.</em></span>
                    </p>
                    <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal
                        bases
                        we rely on in order to process your personal information. As such, we may rely on the following
                        legal bases to process your personal information:</p>
                    <ul>
                        <li><span>Consent.</span> We may process your information if you have given us permission (i.e.,
                            consent) to use
                            your personal information for a specific purpose. You can withdraw your consent at any time.
                            Click <span className="cursor"
                                        onClick={() => scrollToEle("#withdrawconsent")}>here</span> to learn more.
                        </li>
                        <li>
                            <span>Performance of a Contract.</span> We may process your personal information when we
                            believe
                            it is
                            necessary to fulfill our contractual obligations to you, including providing our Services or
                            at
                            your request prior to entering into a contract with you.
                        </li>
                        <li>
                            <span>Legal Obligations.</span> We may process your information where we believe it is
                            necessary
                            for
                            compliance with our legal obligations, such as to cooperate with a law enforcement body or
                            regulatory agency, exercise or defend our legal rights, or disclose your information as
                            evidence
                            in litigation in which we are involved.
                        </li>
                        <li><span>Vital Interests.</span> We may process your information where we believe it is
                            necessary
                            to protect
                            your vital interests or the vital interests of a third party, such as situations involving
                            potential threats to the safety of any person.
                        </li>
                    </ul>
                    <p><span className="text-decoration-underline"><em>If you are located in Canada, this section applies to you.</em></span>
                    </p>
                    <p>We may process your information if you have given us specific permission (i.e., express consent)
                        to
                        use your personal information for a specific purpose, or in situations where your permission can
                        be
                        inferred (i.e., implied consent). You can withdraw your consent at any time. Click <span
                            className="cursor" onClick={() => scrollToEle("#withdrawconsent")}>here</span> to learn
                        more.</p>
                    <p>In some exceptional cases, we may be legally permitted under applicable law to process your
                        information without your consent, including, for example:</p>
                    <ul>
                        <li>
                            If collection is clearly in the interests of an individual and consent cannot be obtained in
                            a
                            timely way
                        </li>
                        <li>For investigations and fraud detection and prevention
                        </li>
                        <li>For business transactions provided certain conditions are met</li>
                        <li>If it is contained in a witness statement and the collection is necessary to assess,
                            process, or
                            settle an insurance claim
                        </li>
                        <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                        <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of
                            financial abuse
                        </li>
                        <li>If it is reasonable to expect collection and use with consent would compromise the
                            availability
                            or the accuracy of the information and the collection is reasonable for purposes related to
                            investigating a breach of an agreement or a contravention of the laws of Canada or a
                            province
                        </li>
                        <li>
                            If disclosure is required to comply with a subpoena, warrant, court order, or rules of the
                            court
                            relating to the production of records
                        </li>
                        <li>
                            If it was produced by an individual in the course of their employment, business, or
                            profession
                            and the collection is consistent with the purposes for which the information was produced
                        </li>
                        <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                        <li>If the information is publicly available and is specified by the regulations</li>
                    </ul>
                </div>

                <div className="my-5" id="whoshare">
                    <h2>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                    <p><span>In Short:</span> We may share information in specific situations described in this section
                        and/or with the following third parties.</p>
                    <p>We may need to share your personal information in the following situations:</p>
                    <ul>
                        <li><span>Business Transfers.</span> We may share or transfer your information in connection
                            with,
                            or during
                            negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                            portion of our business to another company.
                        </li>
                        <li><span>When we use Google Maps Platform APIs.</span> We may share your information with
                            certain
                            Google Maps
                            Platform APIs (e.g., Google Maps API, Places API). To find out more about Google’s Privacy
                            Policy, please refer to this <a href="https://policies.google.com/privacy" target="_blank"
                                                            rel="noreferrer">link.</a>
                        </li>
                        <li>
                            <span>Affiliates.</span> We may share your information with our affiliates, in which case we
                            will require those affiliates to honor this privacy notice. Affiliates include our parent
                            company and any subsidiaries, joint venture partners, or other companies that we control or
                            that
                            are under common control with us.
                        </li>
                        <li><span>Business Partners.</span> We may share your information with our business partners to
                            offer you certain products, services, or promotions.
                        </li>
                    </ul>
                </div>

                <div id="cookies" className="my-5">
                    <h2>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                    <p><span>In Short:</span> We may use cookies and other tracking technologies to collect and store
                        your information.</p>
                    <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
                        store information. Specific information about how we use such technologies and how you can
                        refuse certain cookies is set out in our Cookie Notice.</p>
                </div>

                <div id="sociallogins" className="my-5">
                    <h2>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
                    <p><span>In Short:</span> If you choose to register or log in to our services using a social media
                        account, we may have access to certain information about you.</p>
                    <p>Our Services offer you the ability to register and log in using your third-party social media
                        account
                        details (like your Facebook or Twitter logins). Where you choose to do this, we will receive
                        certain
                        profile information about you from your social media provider. The profile information we
                        receive
                        may vary depending on the social media provider concerned, but will often include your name,
                        email
                        address, friends list, and profile picture, as well as other information you choose to make
                        public
                        on such a social media platform.</p>
                    <p>We will use the information we receive only for the purposes that are described in this privacy
                        notice or that are otherwise made clear to you on the relevant Services. Please note that we do
                        not
                        control, and are not responsible for, other uses of your personal information by your
                        third-party
                        social media provider. We recommend that you review their privacy notice to understand how they
                        collect, use, and share your personal information, and how you can set your privacy preferences
                        on
                        their sites and apps.</p>
                </div>

                <div id="inforetain" className="my-5">
                    <h2>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                    <p><span>In Short:</span> We keep your information for as long as necessary to fulfill the purposes
                        outlined in this privacy notice unless otherwise required by law.</p>
                    <p>We will only keep your personal information for as long as it is necessary for the purposes set
                        out
                        in this privacy notice, unless a longer retention period is required or permitted by law (such
                        as
                        tax, accounting, or other legal requirements). No purpose in this notice will require us keeping
                        your personal information for longer than the period of time in which users have an account with
                        us.</p>
                    <p>When we have no ongoing legitimate business need to process your personal information, we will
                        either
                        delete or anonymize such information, or, if this is not possible (for example, because your
                        personal information has been stored in backup archives), then we will securely store your
                        personal
                        information and isolate it from any further processing until deletion is possible.
                    </p>
                </div>

                <div id="infosafe" className="my-5">
                    <h2>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                    <p><span>In Short:</span> We aim to protect your personal information through a system of
                        organizational
                        and technical security measures.</p>
                    <p>We have implemented appropriate and reasonable technical and organizational security measures
                        designed to protect the security of any personal information we process. However, despite our
                        safeguards and efforts to secure your information, no electronic transmission over the Internet
                        or
                        information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                        guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to
                        defeat our security and improperly collect, access, steal, or modify your information. Although
                        we
                        will do our best to protect your personal information, transmission of personal information to
                        and
                        from our Services is at your own risk. You should only access the Services within a secure
                        environment.</p>
                </div>

                <div id="infominors" className="my-5">
                    <h2>9. DO WE COLLECT INFORMATION FROM MINORS?</h2>
                    <p><span>In Short:</span> We do not knowingly collect data from or market to children under 18 years
                        of
                        age.</p>
                    <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the
                        Services, you represent that you are at least 18 or that you are the parent or guardian of such
                        a
                        minor and consent to such minor dependent’s use of the Services. If we learn that personal
                        information from users less than 18 years of age has been collected, we will deactivate the
                        account
                        and take reasonable measures to promptly delete such data from our records. If you become aware
                        of
                        any data we may have collected from children under age 18, please contact us
                        at <span>adam@nitetoast.com</span>.</p>
                </div>

                <div id="privacyrights" className="my-5">
                    <h2>10. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                    <p><span>In Short:</span> In some regions, such as the European Economic Area (EEA), United Kingdom
                        (UK), and Canada,
                        you have rights that allow you greater access to and control over your personal information. You
                        may
                        review, change, or terminate your account at any time.</p>
                    <p>In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data
                        protection laws. These may include the right (i) to request access and obtain a copy of your
                        personal information, (ii) to request rectification or erasure; (iii) to restrict the processing
                        of
                        your personal information; and (iv) if applicable, to data portability. In certain
                        circumstances,
                        you may also have the right to object to the processing of your personal information. You can
                        make
                        such a request by contacting us by using the contact details provided in the section <span
                            className="cursor" onClick={() => scrollToEle("#contacts")}>"HOW CAN YOU
                        CONTACT US ABOUT THIS NOTICE?"</span> below.</p>
                    <p>We will consider and act upon any request in accordance with applicable data protection laws.
                        If you are located in the EEA or UK and you believe we are unlawfully processing your personal
                        information, you also have the right to complain to your local data protection supervisory
                        authority. You can find their contact details here:
                        <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm."
                           target="_blank"
                           rel="noreferrer">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a>
                    </p>
                    <p>If you are located in Switzerland, the contact details for the data protection authorities are
                        available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                           target="_blank"
                                           rel="noreferrer">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</p>
                    <p id="withdrawconsent"><span>Withdrawing your consent:</span> If we are relying on your consent to
                        process your personal
                        information,
                        which may be express and/or implied consent depending on the applicable law, you have the right
                        to
                        withdraw your consent at any time. You can withdraw your consent at any time by contacting us by
                        using the contact details provided in the section <span className="cursor"
                                                                                onClick={() => scrollToEle("#contacts")}>"HOW CAN YOU
                        CONTACT US ABOUT THIS NOTICE?"</span>
                        below.</p>
                    <p>However, please note that this will not affect the lawfulness of the processing before its
                        withdrawal, nor when applicable law allows, will it affect the processing of your personal
                        information conducted in reliance on lawful processing grounds other than consent.</p>
                    <p><span>Opting out of marketing and promotional communications:</span> You can unsubscribe from our
                        marketing and
                        promotional communications at any time by clicking on the unsubscribe link in the emails that we
                        send, or by contacting us using the details provided in the section <span className="cursor"
                                                                                                  onClick={() => scrollToEle("#contacts")}>"HOW CAN YOU
                        CONTACT US ABOUT THIS NOTICE?"</span> below. You will then be removed from the marketing lists.
                        However, we may still
                        communicate with you — for example, to send you service-related messages that are necessary for
                        the
                        administration and use of your account, to respond to service requests, or for other
                        non-marketing
                        purposes.</p>
                    <div className="my-3">
                        <h3>Account Information</h3>
                        <p>If you would at any time like to review or change the information in your account or
                            terminate
                            your account, you can:</p>
                        <ul>
                            <li>Contact us using the contact information provided.</li>
                            <li>hello@nitetoast.com</li>
                        </ul>
                        <p>Upon your request to terminate your account, we will deactivate or delete your account and
                            information from our active databases. However, we may retain some information in our files
                            to
                            prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal
                            terms
                            and/or comply with applicable legal requirements.</p>
                        <p><span>Cookies and similar technologies:</span> Most Web browsers are set to accept cookies by
                            default. If you
                            prefer, you can usually choose to set your browser to remove cookies and to reject cookies.
                            If
                            you choose to remove cookies or reject cookies, this could affect certain features or
                            services
                            of our Services. To opt out of interest-based advertising by advertisers on our Services
                            visit
                            <a href="http://www.aboutads.info/choices/"
                               target="_blank" rel="noreferrer">http://www.aboutads.info/choices/</a>.</p>
                        <p>If you have questions or comments about your privacy rights, you may email us
                            at <span>hello@nitetoast.com</span>.
                        </p>
                    </div>
                </div>

                <div id="dnt" className="my-5">
                    <h2>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                    <p>Most web browsers and some mobile operating systems and mobile applications include a
                        Do-Not-Track
                        ("DNT") feature or setting you can activate to signal your privacy preference not to have data
                        about
                        your online browsing activities monitored and collected. At this stage no uniform technology
                        standard for recognizing and implementing DNT signals has been finalized. As such, we do not
                        currently respond to DNT browser signals or any other mechanism that automatically communicates
                        your
                        choice not to be tracked online. If a standard for online tracking is adopted that we must
                        follow in
                        the future, we will inform you about that practice in a revised version of this privacy
                        notice.</p>
                </div>

                <div id="caresidents" className="my-5">
                    <h2>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </h2>
                    <p><span>In Short:</span> Yes, if you are a resident of California, you are granted specific rights
                        regarding access to your personal information.</p>
                    <p>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users
                        who
                        are California residents to request and obtain from us, once a year and free of charge,
                        information
                        about categories of personal information (if any) we disclosed to third parties for direct
                        marketing
                        purposes and the names and addresses of all third parties with which we shared personal
                        information
                        in the immediately preceding calendar year. If you are a California resident and would like to
                        make
                        such a request, please submit your request in writing to us using the contact information
                        provided
                        below.</p>
                    <p>If you are under 18 years of age, reside in California, and have a registered account with
                        Services,
                        you have the right to request removal of unwanted data that you publicly post on the Services.
                        To
                        request removal of such data, please contact us using the contact information provided below and
                        include the email address associated with your account and a statement that you reside in
                        California. We will make sure the data is not publicly displayed on the Services, but please be
                        aware that the data may not be completely or comprehensively removed from all our systems (e.g.,
                        backups, etc.).
                    </p>
                    <div className="my3">
                        <h3 className="my-3">CCPA Privacy Notice</h3>
                        <p>The California Code of Regulations defines a "resident" as:</p>
                        <ol>
                            <li>every individual who is in the State of California for other than a temporary or
                                transitory
                                purpose and
                            </li>
                            <li>every individual who is domiciled in the State of California who is outside the State of
                                California for a temporary or transitory purpose
                            </li>
                        </ol>
                        <p>All other individuals are defined as "non-residents."</p>
                        <p>If this definition of "resident" applies to you, we must adhere to certain rights and
                            obligations
                            regarding your personal information.</p>
                    </div>
                    <p className="my-3"><span>What categories of personal information do we collect?</span></p>
                    <p>We have collected the following categories of personal information in the past twelve (12)
                        months:</p>
                    <div className="table-responsive my-4">
                        <table className="table table-hover table-bordered">
                            <thead>
                            <tr>
                                <th scope="col">Category</th>
                                <th scope="col">Examples</th>
                                <th scope="col">Collected</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>A. Identifiers</td>
                                <td>Contact details, such as real name, alias, postal address, telephone or mobile
                                    contact
                                    number, unique personal identifier, online identifier, Internet Protocol address,
                                    email
                                    address, and account name
                                </td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>B. Personal information categories listed in the California Customer Records statute
                                </td>
                                <td>Name, contact information, education, employment, employment history, and financial
                                    information
                                </td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>C. Protected classification characteristics under California or federal law</td>
                                <td>Gender and date of birth
                                </td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>D. Commercial information</td>
                                <td>
                                    Transaction information, purchase history, financial details, and payment
                                    information
                                </td>
                                <td>NO</td>
                            </tr>
                            <tr>
                                <td>E. Biometric information</td>
                                <td>

                                    Fingerprints and voiceprints
                                </td>
                                <td>NO</td>
                            </tr>
                            <tr>
                                <td>F. Internet or other similar network activity</td>
                                <td>
                                    Browsing history, search history, online behavior, interest data, and interactions
                                    with
                                    our and other websites, applications, systems, and advertisements
                                </td>
                                <td>NO</td>
                            </tr>
                            <tr>
                                <td>G. Geolocation data</td>
                                <td>
                                    Device location
                                </td>
                                <td>NO</td>
                            </tr>
                            <tr>
                                <td>H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
                                <td>
                                    Images and audio, video or call recordings created in connection with our business
                                    activities
                                </td>
                                <td>NO</td>
                            </tr>
                            <tr>
                                <td>I. Professional or employment-related information</td>
                                <td>
                                    Business contact details in order to provide you our services at a business level or
                                    job
                                    title, work history, and professional qualifications if you apply for a job with us
                                </td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>J. Education Information</td>
                                <td>
                                    Student records and directory information
                                </td>
                                <td>NO</td>
                            </tr>
                            <tr>
                                <td>K. Inferences drawn from other personal information</td>
                                <td>
                                    Inferences drawn from any of the collected personal information listed above to
                                    create a
                                    profile or summary about, for example, an individual’s preferences and
                                    characteristics
                                </td>
                                <td>YES</td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="my-4">
                        <p>We may also collect other personal information outside of these categories instances where
                            you
                            interact with us in person, online, or by phone or mail in the context of:</p>
                        <ul>
                            <li>Receiving help through our customer support channels;</li>
                            <li>Participation in customer surveys or contests; and</li>
                            <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                        </ul>
                        <p><span>How do we use and share your personal information?</span></p>
                        <p>
                            More information about our data collection and sharing practices can be found in this
                            privacy
                            notice.</p>

                        <p> You may contact us by email at hello@nitetoast.com, or by referring to the contact details
                            at
                            the bottom of this document.</p>

                        <p> If you are using an authorized agent to exercise your right to opt out we may deny a request
                            if
                            the authorized agent does not submit proof that they have been validly authorized to act on
                            your
                            behalf.
                        </p>

                        <p><span>Will your information be shared with anyone else?</span></p>
                        <p>
                            We may disclose your personal information with our service providers pursuant to a written
                            contract between us and each service provider. Each service provider is a for-profit entity
                            that
                            processes the information on our behalf.</p>

                        <p>We may use your personal information for our own business purposes, such as for undertaking
                            internal research for technological development and demonstration. This is not considered to
                            be
                            "selling" of your personal information.</p>

                        <p>Nitetoast LLC has not disclosed or sold any personal information to third parties for a
                            business
                            or commercial purpose in the preceding twelve (12) months. Nitetoast LLC will not sell
                            personal
                            information in the future belonging to website visitors, users, and other consumers.
                        </p>

                        <p><span>Your rights with respect to your personal data</span></p>
                        <p className="text-decoration-underline">Right to request deletion of the data — Request to
                            delete</p>
                        <p>
                            You can ask for the deletion of your personal information. If you ask us to delete your
                            personal
                            information, we will respect your request and delete your personal information, subject to
                            certain exceptions provided by law, such as (but not limited to) the exercise by another
                            consumer of his or her right to free speech, our compliance requirements resulting from a
                            legal
                            obligation, or any processing that may be required to protect against illegal
                            activities.</p>
                        <p className="text-decoration-underline">Right to be informed — Request to know</p>
                        <p>Depending on the circumstances, you have a right to know:</p>
                        <ul className="text-capitalize">
                            <li>whether we collect and use your personal information;</li>
                            <li>the categories of personal information that we collect;</li>
                            <li>the purposes for which the collected personal information is used;</li>
                            <li>whether we sell your personal information to third parties;</li>
                            <li>the categories of personal information that we sold or disclosed for a business purpose;
                            </li>
                            <li>the categories of third parties to whom the personal information was sold or disclosed
                                for a
                                business purpose; and
                            </li>
                            <li>the business or commercial purpose for collecting or selling personal information.</li>
                        </ul>
                        <p>In accordance with applicable law, we are not obligated to provide or delete consumer
                            information
                            that is de-identified in response to a consumer request or to re-identify individual data to
                            verify a consumer request.</p>
                        <p className="text-decoration-underline">Right to Non-Discrimination for the Exercise of a
                            Consumer’s Privacy Rights</p>
                        <p>We will not discriminate against you if you exercise your privacy rights.</p>
                        <p className="text-decoration-underline">Verification process</p>
                        <p>Upon receiving your request, we will need to verify your identity to determine you are the
                            same
                            person about whom we have the information in our system. These verification efforts require
                            us
                            to ask you to provide information so that we can match it with information you have
                            previously
                            provided us. For instance, depending on the type of request you submit, we may ask you to
                            provide certain information so that we can match the information you provide with the
                            information we already have on file, or we may contact you through a communication method
                            (e.g.,
                            phone or email) that you have previously provided to us. We may also use other verification
                            methods as the circumstances dictate.</p>
                        <p>We will only use personal information provided in your request to verify your identity or
                            authority to make the request. To the extent possible, we will avoid requesting additional
                            information from you for the purposes of verification. However, if we cannot verify your
                            identity from the information already maintained by us, we may request that you provide
                            additional information for the purposes of verifying your identity and for security or
                            fraud-prevention purposes. We will delete such additionally provided information as soon as
                            we
                            finish verifying you.</p>
                        <p className="text-decoration-underline">Other privacy rights</p>
                        <ul>
                            <li>You may object to the processing of your personal information.</li>
                            <li>You may request correction of your personal data if it is incorrect or no longer
                                relevant,
                                or ask to restrict the processing of the information.
                            </li>
                            <li>You can designate an authorized agent to make a request under the CCPA on your behalf.
                                We
                                may deny a request from an authorized agent that does not submit proof that they have
                                been
                                validly authorized to act on your behalf in accordance with the CCPA.
                            </li>
                            <li>You may request to opt out from future selling of your personal information to third
                                parties. Upon receiving an opt-out request, we will act upon the request as soon as
                                feasibly
                                possible, but no later than fifteen (15) days from the date of the request submission.
                            </li>
                        </ul>
                        <p>To exercise these rights, you can contact us by email at hello@nitetoast.com, or by referring
                            to
                            the contact details at the bottom of this document. If you have a complaint about how we
                            handle
                            your data, we would like to hear from you.</p>
                    </div>
                </div>

                <div id="policyupdates" className="my-5">
                    <h2>13. DO WE MAKE UPDATES TO THIS NOTICE?
                    </h2>
                    <p><span>In Short:</span> Yes, we will update this notice as necessary to stay compliant with
                        relevant
                        laws.</p>
                    <p>We may update this privacy notice from time to time. The updated version will be indicated by an
                        updated "Revised" date and the updated version will be effective as soon as it is accessible. If
                        we
                        make material changes to this privacy notice, we may notify you either by prominently posting a
                        notice of such changes or by directly sending you a notification. We encourage you to review
                        this
                        privacy notice frequently to be informed of how we are protecting your information.</p>
                </div>

                <div id="contacts" className="my-5">
                    <h2>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </h2>
                    <p>If you have questions or comments about this notice, you may contact our Data Protection Officer
                        (DPO), Adam Platsky, by email at <span>adam@nitetoast.com</span>, by phone
                        at <span>607-349-3069</span>, or by post to:</p>

                    <p className="mb-0">Nitetoast LLC</p>
                    <p className="mb-0">Adam Platsky</p>
                    <p className="mb-0">224 W 35th St</p>
                    <p className="mb-0">Ste 500 PMB 43</p>
                    <p className="mb-0">New York, NY 10001</p>
                    <p className="mb-0">United States</p>

                </div>

                <div id="request" className="my-5">
                    <h2>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                    <p>Based on the applicable laws of your country, you may have the right to request access to the
                        personal information we collect from you, change that information, or delete it in some
                        circumstances. To request to review, update, or delete your personal information, please submit
                        a
                        request form by clicking <a
                            href="https://app.termly.io/notify/0bf7e9d0-109f-4f42-926c-3dd50e579995" target="_blank"
                            rel="noreferrer">here.</a>
                    </p>
                    <p>This privacy policy was created using <a
                        href="https://termly.io/products/privacy-policy-generator/"
                        target="_blank" rel="noreferrer">Termly's Privacy Policy Generator.</a></p>
                </div>

            </div>
        </div>
    </div>);
};

export default PrivacyPolicy;