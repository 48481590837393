import React from "react";
import PropTypes from "prop-types"
import Modal from "react-bootstrap/Modal";
import popupLogo from "../../assets/images/Bike_ride.png";

const ErrorPopup = ({
                        isConfirmPopup,
                        cancelPopup,
                    }) => {

    return (
        <>
            <Modal show={isConfirmPopup} centered size="lg" className="introduce-popup error-popup">
                <Modal.Body>
                    <h2 className="my-3 error-title text-center">Error : (</h2>
                    <div className="text-center">
                        <img src={popupLogo} alt="popup-logo" className="img-fluid"/>
                    </div>
                    <div className="error-info mt-5">
                        <p className="error-msg text-center">Sorry, something went wrong.</p>
                        <p className="error-msg text-center">We'll look into it for you, please try again later.</p>
                    </div>
                    <div className="text-center mt-4">
                        <button onClick={cancelPopup} className="primary-dark-btn">Back</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

ErrorPopup.propTypes = {
    isConfirmPopup: PropTypes.bool,
    cancelPopup: PropTypes.func,
    handleSubmit: PropTypes.func,
};

export default ErrorPopup;
