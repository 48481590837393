import React, {useEffect} from 'react';
import aboutImg1 from "../../assets/images/aboutImg1.svg";
import aboutImg2 from "../../assets/images/aboutImg2.svg";
import role1 from "../../assets/images/leader.png";
import role2 from "../../assets/images/manager.png";
import role3 from "../../assets/images/seller.png";
import role4 from "../../assets/images/doer.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useHistory} from "react-router-dom";
import arrowsImage from "../../assets/images/bottom-arrrows.png";

const About = () => {

    const history = useHistory();

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        document.querySelectorAll('img')
            .forEach((img) =>
                img.addEventListener('load', () =>
                    AOS.refresh()
                )
            );
    }, [])

    const redirectToTryBeta = () => {
        history.push({
            pathname: "/",
        });
    }

    return (
        <div className="about">
            <section className="about-info">
                <div className="container">
                    <div className="row">
                        <div className="col-12" data-aos="fade-up"
                             data-aos-duration="1000" data-aos-offset="50">
                            <h2 className="text-center h2-heading">What are role types?</h2>
                            <p className="para mt-4 text-center">We all know our time is valuable, but we don’t always put a number on
                                it. That’s where Nitetoast comes in. We think about time differently. Not all time is
                                equally as valuable, especially when it comes to meetings. Each role type (Doer, Seller,
                                Manager, and Leader) needs a different mix of flow time and meting types.</p>
                            <div className="col-12 mx-auto">
                                <div className="d-flex justify-content-between roles my-5">
                                    <div>
                                        <img src={role1} alt="role" className="img-fluid"/>
                                        <h4 className="text-center mt-3">Leader</h4>
                                    </div>
                                    <div>
                                        <img src={role2} alt="role" className="img-fluid"/>
                                        <h4 className="text-center mt-3">Manager</h4>
                                    </div>
                                    <div>
                                        <img src={role3} alt="role" className="img-fluid"/>
                                        <h4 className="text-center mt-3">Seller</h4>
                                    </div>
                                    <div>
                                        <img src={role4} alt="role" className="img-fluid"/>
                                        <h4 className="text-center mt-3">Doer</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="try-beta">
                <div className="connect-absolute">
                    <p className="mb-0">Which role type are you?</p>
                    <img src={arrowsImage} alt="connect1"/>
                </div>
                <div className="container">
                    {/*<h2 className="h2-heading text-center">Which role type are you?</h2>*/}
                    <div className="row mt-md-5 mt-3">
                        <div className="col-lg-5 col-md-4 col-12" data-aos="fade-up"
                             data-aos-duration="1000" data-aos-offset="150">
                            <div className="text-center">
                                <img src={aboutImg1} alt="about-us-2" className="img-fluid img2"/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-8 col-12 text-start mt-md-0" data-aos="fade-up"
                             data-aos-duration="1000" data-aos-offset="150">
                            <div className="mt-4">
                                <p className="para text-start"><b>The Doers</b> include developers, engineers, and
                                    designers.
                                    should be in
                                    as few meetings as possible. They need a significant amount of uninterrupted focus
                                    time to build things that clients love.</p>
                                <p className="para text-start">An optimized <b>doer</b> schedule includes at least
                                    four hours of flow time daily.</p>
                                <p className="para text-start"><b>The Sellers</b> include client facing team members
                                    including customer success managers, and account strategists. They need time to
                                    create delightful experiences that grow the business.</p>
                                <p className="para text-start">An optimized <b>seller</b> schedule
                                    consists primarily of external meetings and demos.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-md-5 mt-3 pt-md-5 pt-0">
                        <div className="col-lg-7 col-md-8 col-12 order-md-0 order-1 mt-md-0 mt-4" data-aos="fade-up"
                             data-aos-duration="1000" data-aos-offset="150">
                            <div className="mt-4">
                                <p className="para">
                                    <b>The Managers</b> include project managers, product owners, and office managers.
                                    They
                                    need to be coordinating across silos, making connections, and assembling teams.</p>
                                <p className="para">An
                                    optimized <b>manager</b> schedule consists mostly internal meetings with unique
                                    attendees.</p>
                                <p className="para">
                                    <b>The Leaders</b> include executives and subject matter experts. They need to be
                                    sharing
                                    their vision, providing guidance, and collecting feedback.</p>
                                <p className="para">An optimized <b>leader</b> schedule includes a mix of internal and
                                    external attendees and includes a number of one on ones.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-4 col-12 order-md-1 order-0" data-aos="fade-up"
                             data-aos-duration="1000" data-aos-offset="150">
                            <div className="text-center">
                                <img src={aboutImg2} alt="about-us-3" className="img-fluid img3"/>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-md-5 mt-3">
                        <h2 className="analyze-calender">Ready to analyze your calendar?</h2>
                        <button className="secondary-light-btn mt-4" onClick={redirectToTryBeta}>Try Beta</button>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default About;