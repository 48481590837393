import React, {useEffect, useState} from "react";
import PropTypes from "prop-types"
import Modal from "react-bootstrap/Modal";
import {useHistory} from "react-router-dom";

const IntroducePopup = ({
                            isConfirmPopup,
                            cancelPopup,
                            handleSubmit,
                        }) => {
    const history = useHistory();
    const [form, setForm] = useState({
        name: "",
        email: "",
    });

    const [role, setRole] = useState("Doer");
    const [searchBtnDisabled, setSearchBtnDisabled] = useState(true);

    const handleRole = (role) => {
        setRole(role);
    }

    useEffect(() => {
        if (form.name === "" || form.email === "") {
            setSearchBtnDisabled(true)
        } else {
            if (form.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                setSearchBtnDisabled(false);
            } else {
                setSearchBtnDisabled(true);
            }
        }
    }, [form]);

    const changeHandler = (e, name) => {
        if (e && e.target) {
            const {name, value} = e.target;
            setForm((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            const {value} = e;
            setForm((prevState) => ({...prevState, [name]: value}));
        }
    };

    return (
        <>
            <Modal show={isConfirmPopup} centered size="lg" className="introduce-popup">
                <Modal.Body>
                    <span onClick={cancelPopup}>X</span>
                    <div>
                        <h2 className="my-3">Your calendar is being analyzed!</h2>
                        <p className='para'>While you wait, please answer a few questions:</p>
                    </div>
                    <div>
                        <div className="introduce-text">
                            <p className="para m-0">Please introduce yourself!</p>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-lg-8 col-10 col-12">
                                    <div className="form-group my-3">
                                        <input type="text" className="form-control"
                                               aria-describedby="emailHelp" placeholder="Name"
                                               name="name"
                                               value={form.name}
                                               onChange={changeHandler}
                                        />
                                    </div>
                                    <div className="form-group my-3">
                                        <input type="email" className="form-control"
                                               aria-describedby="emailHelp" placeholder="Email"
                                               name="email"
                                               value={form.email}
                                               onChange={changeHandler}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="select-roles">
                                <p className="para">Select your role type:</p>
                                <p className="para-2">For role type descriptions, <u className="underline" href="dummy" onClick={() => {
                                    history.push("/role-type")
                                }}>click here.</u></p>
                            </div>
                            <div className="col-lg-8 col-md-10 col-12">
                                <div className="d-flex flex-wrap roles justify-content-between">
                                    <p className={`para ${role === "Doer" ? "color-role" : ""}`} onClick={() => {
                                        handleRole("Doer")
                                    }}>Doer</p>
                                    <p className={`para ${role === "Seller" ? "color-role" : ""}`} onClick={() => {
                                        handleRole("Seller")
                                    }}>Seller</p>
                                    <p className={`para ${role === "Manager" ? "color-role" : ""}`} onClick={() => {
                                        handleRole("Manager")
                                    }}>Manager</p>
                                    <p className={`para ${role === "Leader" ? "color-role" : ""}`} onClick={() => {
                                        handleRole("Leader")
                                    }}>Leader</p>
                                </div>
                            </div>
                            <div className="text-center mt-3">
                                <button className={searchBtnDisabled ? "disabled-primary-dark-btn" : "primary-dark-btn"}
                                        disabled={searchBtnDisabled}
                                        onClick={(e) => handleSubmit(e, form, role)}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                    {/*<img src={popupLogo} alt="popup-logo" className="popup-logo"/>*/}
                </Modal.Body>
            </Modal>
        </>
    );
};

IntroducePopup.propTypes = {
    isConfirmPopup: PropTypes.bool,
    cancelPopup: PropTypes.func,
    handleSubmit: PropTypes.func,
};

export default IntroducePopup;
