import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import logo from "../../assets/images/logo.png";

const Logo = () => {
    return (
        <div className="logo-wrapper">
            <NavLink exact to="/" className="nav-logo">
                <img src={logo} alt="logo" className="me-3 mb-1"/>
                Nitetoast
            </NavLink>
        </div>
    )
}

const Header = () => {
    const [click, setClick] = React.useState(false);
    const [linksColor, setLinksColor] = useState(false);

    const location = useLocation();
    useEffect(() => {
        if (location) {
            if (location.pathname === "/") {
                setLinksColor(true);
            } else {
                setLinksColor(false);
            }
        }
    }, [location])

    useEffect(() => {
        if (click) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [click]);

    const handleClick = () => setClick(!click);
    const Close = () => setClick(false);
    return (
        <div>
            <div className={`${click  ? "main-container" : ""}`} onClick={() => Close()}/>
            <nav className={`navbar shadow-bg bg-white`}
                 onClick={(e) => e.stopPropagation()}>
                <div className="container nav-container">
                    <Logo/>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/"
                                activeClassName="active"
                                className={linksColor ? "nav-links" : "nav-linksBlack"}
                                onClick={click ? handleClick : null}
                            >
                                Try beta
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/role-type"
                                activeClassName="active"
                                className={linksColor ? "nav-links" : "nav-linksBlack"}
                                onClick={click ? handleClick : null}
                            >
                                Role types
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/dashboard"
                                activeClassName="active"
                                className={linksColor ? "nav-links" : "nav-linksBlack"}
                                onClick={click ? handleClick : null}
                            >
                                Dashboard
                            </NavLink>
                        </li>
                    </ul>
                    <div className="nav-icon" onClick={handleClick}>
                        {
                            click ? (
                                <div className="hamburger is-active" id="hamburger-1">
                                    <span className="line"/>
                                    <span className="line"/>
                                    <span className="line"/>
                                </div>
                            ) : (
                                <div className="hamburger" id="hamburger-1">
                                    <span className="line"/>
                                    <span className="line"/>
                                    <span className="line"/>
                                </div>
                            )
                        }
                    </div>
                </div>
            </nav>
        </ div>
    );
};

export default Header;