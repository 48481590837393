import React from 'react';

const PageNotFound = () => {
    return (
        <div className="page-not-found d-flex justify-content-center align-items-center">
            <div className="flex-column text-center">
                <h3>404</h3>
                <h2>Page not found</h2>
            </div>
        </div>
    );
};

export default PageNotFound;