import React, {useMemo, useState} from "react";
import PropTypes from "prop-types"
import Modal from "react-bootstrap/Modal";
import uploadImage from "../../assets/images/upload-image.png";
import uploadIcon from "../../assets/images/upload-icon.png";
import {useDropzone} from "react-dropzone";
import fileIcon from "../../assets/images/file.png";
import deleteIcon from "../../assets/images/delete.png";
import googleCal from "../../assets/images/google_calendar_icon.svg";
import outlookCal from "../../assets/images/outlook-icon.svg";

const baseStyle = {
    borderWidth: 2,
    borderColor: 'rgb(200 201 201)',
    borderStyle: 'dashed',
    outline: 'none',
    borderRadius: "30px",
    transition: 'border .24s ease-in-out',
    padding: "30px 15px"
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#60bd8f'
};

const rejectStyle = {
    borderColor: '#ce6276'
};

const UploadDocPopup = ({
                            isPopup,
                            cancelPopup,
                            handleSubmit,
                            setCalendarValue
                        }) => {
    const [myFiles, setMyFiles] = useState([]);
    const [calendar, setCalendar] = useState("");

    const removeFile = file => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }

    const onDropAccepted = (f) => {
        setMyFiles(f);
    };

    const onDropRejected = (f) => {
        console.log(f);
    };

    const {
        getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject
    } = useDropzone({
        accept: ".csv,text/csv",
        multiple: false,
        maxFiles: 5,
        onDropAccepted,
        onDropRejected,
        noClick: true,
        noKeyboard: true
    });

    const style = useMemo(() => ({
        ...baseStyle, ...(isDragActive ? activeStyle : {}), ...(isDragAccept ? acceptStyle : {}), ...(isDragReject ? rejectStyle : {})
    }), [isDragActive, isDragReject, isDragAccept]);

    const files = myFiles.map((file, index) => (
        <div key={index}
             className="filePreview d-flex justify-content-between border bg-white p-3">
            <div className="d-flex">
                <img src={fileIcon} alt="fileIcon" width="30"/>
                <h6 className="m-0 align-self-center mx-2 filename img-fluid">{file.path}</h6>
            </div>
            <div className="align-self-center">
                <img src={deleteIcon} alt="deleteIcon" style={{cursor: "pointer"}} className="deleteIcon" width="25"
                     onClick={removeFile(file)}/>
            </div>
        </div>))

    const handleSubmitDoc = (files) => {
        cancelPopup();
        handleSubmit(files);
        setCalendarValue(calendar);
    }

    const handleCalendarSelect = (calendar) => {
        setCalendar(calendar);
    }

    return (
        <>
            <Modal show={isPopup} centered size="lg" className="upload-popup">
                <Modal.Body>
                    <span onClick={cancelPopup}>X</span>
                    <section id="upload-file">
                        <div className="row">
                            <div className="col-12 mx-auto">
                                <div className="d-flex justify-content-center position-relative px-md-3 px-0">
                                    <div className="text-center align-self-center mt-md-0 mt-4">
                                        <h2>Upload your Google or Outlook calendar as a .CSV</h2>
                                    </div>
                                    <div className="upload-icon">
                                        <img src={uploadIcon} alt="upload-icon"/>
                                    </div>
                                </div>
                                <div className="px-3">
                                    <a href="https://nitetoast.com/outlook-instructions" target="_blank"
                                       rel="noreferrer"><p
                                        className="m-0">Outlook
                                        instructions(PC Only)</p></a>
                                    <a href="https://nitetoast.com/google-instructions" target="_blank"
                                       rel="noreferrer"><p
                                        className="m-0">Google
                                        instructions</p></a>
                                </div>

                                <div className="try-beta-main">
                                    <div className="row p-md-4 p-3 d-flex justify-content-center">
                                        <div className="col-12 p-0">
                                            <div
                                                className="text-center uploader d-flex flex-column justify-content-center align-items-center" {...getRootProps({style})}>
                                                <img src={uploadImage} alt="upload-icon"
                                                     className="upload-image"/>
                                                <p className="para mt-3">Drop your file here</p>
                                                {isDragReject && (<div className="validationError">
                                                    <span className="errorMsg">Please upload valid file.</span>
                                                    <p className="errorMsg m-0">Valid format (.CSV)</p>
                                                    <p className="errorMsg">You can upload 1 file at a time.</p>
                                                </div>)}
                                                <h6 className="or-text">OR</h6>

                                                <div className="file_input_wrap">
                                                    <input className="hide" {...getInputProps()}/>
                                                    <label htmlFor="imageUpload" className="primary-dark-btn"
                                                           onClick={open}>Browse</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-center">
                                        <div
                                            className="col-12 d-flex text-center flex-column uploaded px-md-5 px-1 mt-md-0 mt-2">
                                            <div>
                                                {/*{files?.length > 0 &&  <h6>Uploaded files </h6>}*/}
                                                <div className="preview-container">
                                                    {files.length > 0 && files}
                                                </div>
                                            </div>
                                            {files?.length > 0 && (
                                                <div className="mt-3 select-calendar-type text-start">
                                                    <p className="mb-1 e">Select your calendar type: </p>
                                                    <div className="d-flex">
                                                        <div
                                                            className={`d-flex radio justify-content-around ${calendar === "google" ? "color-bg-calendar" : ""}`}
                                                            onClick={() => {
                                                                handleCalendarSelect("google")
                                                            }}>
                                                            <img src={googleCal} alt="google_calendar" width="30"/>
                                                            <p className="my-0 align-self-center">Google</p>
                                                        </div>
                                                        <div
                                                            className={`d-flex radio justify-content-around ${calendar === "outlook" ? "color-bg-calendar" : ""}`}
                                                            onClick={() => {
                                                                handleCalendarSelect("outlook")
                                                            }}>
                                                            <img src={outlookCal} alt="outlook_calendar" width="25"/>
                                                            <p className="my-0 align-self-center">Outlook</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {files?.length > 0 && calendar && <div className="text-center">
                                        <button className="primary-dark-btn mt-4" onClick={() => {
                                            handleSubmitDoc(myFiles)
                                        }}>Submit
                                        </button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>
        </>
    );
};

UploadDocPopup.propTypes = {
    isPopup: PropTypes.bool,
    cancelPopup: PropTypes.func,
    handleSubmit: PropTypes.func,
    setCalendarValue: PropTypes.func,
};

export default UploadDocPopup;
