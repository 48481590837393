import React from "react";

const Loader = () => {
    return (
        <div className="loader">
            <div>
                {/*<div className="loader-component"/>*/}
                <ul>
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                </ul>
            </div>
        </div>
    );
};

export default Loader;
