import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

import firstImage from "../../assets/images/hero-section-image-1.png";
import secondImage from "../../assets/images/hero-section-image-2.png";
import connectImage1 from "../../assets/images/connect-image-1.png";
import connectImage2 from "../../assets/images/connect-image-2.png";
import googleIcon from "../../assets/images/google-icon.svg";
import outlookIcon from "../../assets/images/outlook-icon.svg";
import csvIcon from "../../assets/images/csv-icon.svg";
import arrowsImage from "../../assets/images/bottom-arrrows.png";

import ApiCalendar from 'react-google-calendar-api';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import IntroducePopup from "../../components/CommonComponent/IntroducePopup";
import ErrorPopup from "../../components/CommonComponent/ErrorPopup";
import Loader from "../../components/CommonComponent/Loader";
import moment from "moment";
import UploadDocPopup from "../../components/CommonComponent/UploadDocPopup";
import {toast} from "react-toastify";
import MicrosoftLogin from "react-microsoft-login";

const TryBeta = () => {
    const history = useHistory();

    const [calendarResult, setCalendarResult] = useState([]);
    const [myFiles, setMyFiles] = useState([]);
    const [show, setShow] = useState(false);
    const [isUploadDocPopup, setUploadDocPopup] = useState(false);
    const [showError, setShowError] = useState(false);
    const [loading, setLoader] = useState(false);
    const [fetchCalendar, setFetchCalendar] = useState(false);
    const [calendar, setCalendar] = useState("");
    const scopes = ['openid', 'profile', 'offline_access', 'Calendars.ReadWrite'];
    useEffect(() => {
        window.sessionStorage.clear();
        AOS.init();
        AOS.refresh();
        document.querySelectorAll('img')
            .forEach((img) => img.addEventListener('load', () => AOS.refresh()));

    }, []);

    const handleGoogleCalendar = async () => {
        if (ApiCalendar.sign) {
            ApiCalendar.handleAuthClick().then(() => {
                getGoogleCalendarData();
            });
        } else {
            getGoogleCalendarData();
        }
    }


    const getGoogleCalendarData = () => {
        let startOfMonth = moment(new Date().setMonth(new Date().getMonth() - 1)).format("YYYY-MM-DD hh:mm");
        let endOfMonth = moment().format('YYYY-MM-DD hh:mm');
        ApiCalendar.handleAuthClick().then(() => {
            ApiCalendar.listEvents({
                timeMin: new Date(startOfMonth).toISOString(),
                timeMax: new Date(endOfMonth).toISOString(),
                maxResults: moment().daysInMonth(),
                orderBy: "updated"
            }).then(({result}) => {
                if (result?.items?.length === 0) {
                    toast.warning("You dont have events in your calendar.");
                } else {
                    let arr = [];
                    const deleteKey = (obj, path) => {
                        const _obj = JSON.parse(JSON.stringify(obj));
                        const keys = path.split('.');

                        keys.reduce((acc, key, index) => {
                            if (index === keys.length - 1) {
                                delete acc[key];
                                return true;
                            }
                            return acc[key];
                        }, _obj);

                        return _obj;
                    }
                    const PATH_TO_DELETE = 'conferenceData.conferenceSolution.iconUri';
                    // eslint-disable-next-line
                    result?.items?.map((data) => {
                        if (data?.conferenceData?.conferenceSolution?.iconUri) {
                            let formattedData = deleteKey(data, PATH_TO_DELETE);
                            arr.push(formattedData)
                        } else {
                            arr.push(data);
                        }
                    })
                    setCalendarResult(arr);
                    setFetchCalendar(true);
                    setShow(true);
                    setCalendar("google");
                }
                ApiCalendar.handleSignoutClick();
            });
        });
    }

    const handleSubmit = (files) => {
        setShow(true);
        setMyFiles(files);
    }

    const handleCancel = () => {
        setShow(false);
    }

    const handleCancelErrorPopup = () => {
        setShowError(false);
    }

    const handleCSVPopup = () => {
        setUploadDocPopup(false);
    }

    const handleApiCall = (e, form, role) => {
        e.preventDefault();
        if (fetchCalendar) {
            setLoader(true);
            setShow(false);
            const formData = new FormData();
            formData.append('events', JSON.stringify(calendarResult));
            formData.append('email', form?.email);
            formData.append('user', role);
            axios({
                method: "post",
                url: `https://beta-api.nitetoast.com/${calendar}json2csv/`,
                data: formData,
                headers: {"Content-Type": "multipart/form-data", "Accept": "application/json"},
            })
                .then((response) => {
                    if (!response?.data?.data) {
                        setLoader(false);
                        toast.warning(response?.data?.meta?.message)
                    } else {
                        setLoader(false);
                        setFetchCalendar(false);
                        history.push({
                            pathname: "/dashboard", state: {
                                data: response?.data?.data,
                                role: role,
                                user: form,
                                calendarResult: calendarResult,
                                isFile: false,
                                email: form?.email,
                                calendar: calendar
                            },
                        });
                        window.scroll(0, 0);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setShow(false);
                    setLoader(false);
                    setShowError(true);
                });
        } else {
            setLoader(true);
            setShow(false);
            myFiles.forEach(file => {
                const formData = new FormData();
                formData.append('key', file, file.name);
                formData.append('email', form?.email);
                formData.append('user', role);
                axios({
                    method: "post",
                    url: `https://beta-api.nitetoast.com/${calendar}/`,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data", "Accept": "application/json"},
                })
                    .then((response) => {
                        setMyFiles([]);
                        setLoader(false);
                        history.push({
                            pathname: "/dashboard", state: {
                                data: response?.data?.data,
                                role: role,
                                user: form,
                                file: file,
                                filename: file.name,
                                isFile: true,
                                email: form?.email,
                                calendar: calendar
                            },
                        });
                        window.scroll(0, 0);
                    })
                    .catch((error) => {
                        console.log(error);
                        setShow(false);
                        setLoader(false);
                        setShowError(true);
                    });
            })
        }
    }

    const authHandler = (err, data) => {
        window.sessionStorage.clear();
        if (data?.accessToken) {
            const headers = {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${data.accessToken}`
            }
            let startOfMonth = moment(new Date().setMonth(new Date().getMonth() - 1)).format("YYYY-MM-DD hh:mm");
            let endOfMonth = moment().format('YYYY-MM-DD hh:mm');
            axios.get('https://graph.microsoft.com/v1.0/me/calendarview', {
                headers: headers, params: {
                    $orderby: 'start/dateTime',
                    startdatetime: new Date(startOfMonth).toISOString(),
                    enddatetime: new Date(endOfMonth).toISOString(),
                }
            }).then((res) => {
                if (res?.data?.value?.length === 0) {
                    toast.warning("You dont have events in your calendar.");
                } else {
                    setCalendar("outlook");
                    setCalendarResult(res?.data?.value);
                    setFetchCalendar(true);
                    setShow(true);
                }
            }).catch((e) => {
                toast.error(e?.message);
            });
        }
    };

    return (<>
        {loading && <Loader/>}
        <div className="trybeta">
            {show && (<IntroducePopup
                isConfirmPopup={show}
                handleSubmit={handleApiCall}
                cancelPopup={handleCancel}
            />)}
            {isUploadDocPopup && (<UploadDocPopup
                isPopup={isUploadDocPopup}
                handleSubmit={handleSubmit}
                cancelPopup={handleCSVPopup}
                setCalendarValue={setCalendar}
            />)}
            {showError && (<ErrorPopup
                isConfirmPopup={showError}
                cancelPopup={handleCancelErrorPopup}
            />)}
            <section className="intro position-relative">
                <div className="container" data-aos="fade-up"
                     data-aos-duration="1000" data-aos-offset="50">
                    <div className="col-12 col-md-12 col-lg-12">
                        <h5 className="mb-0">Welcome to</h5>
                        <h1 className="m-0">
                            Nitetoast<small className="ms-2">beta</small>
                        </h1>
                    </div>
                    <div
                        className="section-parent mt-4 d-flex align-items-start justify-content-lg-between justify-content-center">
                        <div className="first-section">
                            <div>
                                <div className="mt-md-5 mt-2">
                                    <h6>What does beta do?</h6>
                                    <p>What can beta do? Get detailed analysis of your time along with an “optimization
                                        score” based on how your calendar compares to others in similar roles.</p>
                                </div>
                            </div>
                        </div>
                        <div className="second-section d-flex justify-content-end">
                            <img src={firstImage} alt="try-beta" className="try-beta-img-1 d-block align-self-end"/>
                            <img src={secondImage} alt="try-beta-2" className="try-beta-img-2 d-block"/>
                        </div>
                    </div>
                </div>
                <div className="connect-absolute">
                    <p className="mb-0">Connect your calendar</p>
                    <p>to get started</p>
                    <img src={arrowsImage} alt="connect1"/>
                </div>
            </section>
            <section className="connect">
                <div className="container" data-aos="fade-up"
                     data-aos-duration="1000" data-aos-offset="50">
                    <div className="d-flex flex-wrap align-items-end justify-content-lg-between justify-content-center">
                        <div className="first-section">
                            <div className="d-flex">
                                <div className="buttons">
                                    <button className="mb-4" onClick={(e) => handleGoogleCalendar(e)}>
                                        <img src={googleIcon} alt="google-icon" className="img-fluid"/>
                                        Connect Google calendar
                                    </button>
                                    <MicrosoftLogin prompt="select_account" useLocalStorageCache={false}
                                                    graphScopes={scopes}
                                                    clientId={"f9520d6e-a9b6-4428-a2ca-26fec623100c"}
                                                    authCallback={authHandler} children={<button className="mb-4">
                                        <img src={outlookIcon} alt="google-icon" className="img-fluid"/>
                                        Connect Outlook calendar
                                    </button>}/>
                                    <button className="mb-4" onClick={() => setUploadDocPopup(true)}>
                                        <img src={csvIcon} alt="google-icon" className="img-fluid"/>
                                        Upload .csv file
                                    </button>
                                </div>
                                <div className="img-container">
                                    <img src={connectImage1} alt="connect1"
                                         className="align-self-end connect-img1"/>
                                </div>
                            </div>
                        </div>
                        <div className="second-section">
                            <img src={connectImage2} alt="connect2" className="connect-img2"/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>);
};

export default TryBeta;
