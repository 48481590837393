import React from 'react';

const Footer = () => {
    return (
        <div className="footer bg-dark">
            <div className="container">
                <div className="row justify-content-center">
                   <div className="col-lg-5 col-md-6 col-12 text-center">
                       <h2 className="text-light">Hello@nitetoast.com</h2>
                       <p className="text-light mb-0 mt-4">224 W 35th St Ste 500 PMB 43 <br className="d-md-block d-none"/>New York, NY 10001</p>
                   </div>
                    <div className="text-center mt-5">
                        <a className="privacy" href="/privacy-policy" target="_blank">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;